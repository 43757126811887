import './App.css';
import visual from './visual-alizarin.png';
import useSound from "use-sound";
import React, { useState, useEffect } from 'react'

import armStart from "./armStart.mp3"
import ringEnd from "./ringEnd.mp3";
import win from "./win.mp3";

const level0 = '🚲';
const level1 = '🚗';
const level2 = '🏎️';
const level3 = '🚀';

const level0Limit = 50;
const level1Limit = 75;
const level2Limit = 95;
const level3Limit = 100;

const title = "DevBoost Performance Prognose";
const firstValve = "Product Discovery";
const secondValve = "Product Forming";
const thirdValve = "Product Shipping";

const prognoseInProgressTitle = "..."
const prognoseInProgressText = "Wir berechnen Deine persönliche Prognose.";

const unbalancedPipelineTitle = "Deine Produkt Pipeline ist unausgeglichen!";
const unbalancedPipelineText = "Suche nach dem Bottleneck und optimiere anschließend iterativ.";

const level0Title = "Deine Produkt Pipeline funktioniert, könnte aber schneller sein!";
const level0Text = "Investiere aktiv in ihre Optimierung.";

const level1Title = "Deine Produkt Pipeline ist balanciert, leidet allerdings unter Altlasten!";
const level1Text = "Das zu ändern ist Deine wichtigste Aufgabe.";

const level2Title = "Deine Produkt Pipeline ist echt ausgefuchst!";
const level2Text = "Finde weitere Möglichkeiten die Geschwindigkeit noch zu erhöhen.";

const level3Title = "Deine Produkt Pipeline läuft auf Hochtouren!";
const level3Text = "Bleib dran, damit es so bleibt.";

const runTest = "Test Starten";

const App = ({ id, owned, close, expires }) => {

    const [spin, setSpin] = useState(false)
    const [ring1, setRing1] = useState(level0Limit)
    const [ring2, setRing2] = useState(level1Limit)
    const [ring3, setRing3] = useState(level2Limit)
    const [benchmarkResult, setBenchmarkResult] = useState()
    const [benchmarkResultTitle, setBenchmarkResultTitle] = useState()

    const [playArmStart] = useSound(
        armStart,
        {}
    );

    const [playRingEnd] = useSound(
        ringEnd,
        {}
    );

    const [playWin] = useSound(
        win,
        {}
    );

    const startPlay = () => {
        if (ring3 > 1 || !spin) {
            setSpin(true)
            playArmStart();
            setRing1(undefined)
            setRing2(undefined)
            setRing3(undefined)
            setTimeout(function () {
                generateRandomRings()
            }, 2000)

        }
    }



    useEffect(() => {
        const keyDownHandler = event => {
          if (event.key === 'Enter') {
            event.preventDefault();
            startPlay();
          }
        };
    
        document.addEventListener('keydown', keyDownHandler);
    
        return () => {
          document.removeEventListener('keydown', keyDownHandler);
        };
      }, [startPlay]);
    


    useEffect(() => {
        const computeBenchmarkResult = () => {
            if (spin) {
                setBenchmarkResultTitle(prognoseInProgressTitle);
                setBenchmarkResult(prognoseInProgressText);
                return;
            }
            if (ring1 <= level0Limit &&
                ring2 <= level0Limit &&
                ring3 <= level0Limit) {
                playWin()
                setBenchmarkResultTitle(level0Title);
                setBenchmarkResult(level0Text);
                return;
            }

            if (
                ring1 > level0Limit && ring1 <= level1Limit &&
                ring2 > level0Limit && ring2 <= level1Limit &&
                ring3 > level0Limit && ring3 <= level1Limit) {
                playWin()
                setBenchmarkResultTitle(level1Title);
                setBenchmarkResult(level1Text);
                return;
            }

            if (
                ring1 > level1Limit && ring1 <= level2Limit &&
                ring2 > level1Limit && ring2 <= level2Limit &&
                ring3 > level1Limit && ring3 <= level2Limit) {
                playWin()
                setBenchmarkResultTitle(level2Title);
                setBenchmarkResult(level2Text);
                return;
            }

            if (
                ring1 > level2Limit && ring1 <= level3Limit &&
                ring2 > level2Limit && ring2 <= level3Limit &&
                ring3 > level2Limit && ring3 <= level3Limit) {
                playWin()
                setBenchmarkResultTitle(level3Title);
                setBenchmarkResult(level3Text);
                return;

            }

            if (
                ring1 !== undefined &&
                ring2 !== undefined &&
                ring3 !== undefined) {
                setBenchmarkResultTitle(unbalancedPipelineTitle);
                setBenchmarkResult(unbalancedPipelineText)
                return;

            }
            setBenchmarkResultTitle("Press the test buzzer!");
            setBenchmarkResult("The oracle will guess your Product Pipeline performance.")
        }

        computeBenchmarkResult()
    }, [ring1, ring2, ring3, spin])


    const renderRow = (ring) => {

        if (spin && ring === undefined) {
            return (
                <>
                    <div className="ringMoving">{level3}</div>
                    <div className="ringMoving">{level0}</div>
                    <div className="ringMoving">{level1}</div>
                    <div className="ringMoving">{level2}</div>
                </>
            )
        }

        if (ring >= 1 && ring <= level0Limit) {
            return (
                <>
                    <div className="ringEnd">{level3}</div>
                    <div className="ringEnd">{level0}</div>
                    <div className="ringEnd">{level1}</div>
                    <div className="ringEnd">{level2}</div>
                </>
            )
        }

        if (ring > level0Limit && ring <= level1Limit) {
            return (
                <>
                    <div className="ringEnd">{level0}</div>
                    <div className="ringEnd">{level1}</div>
                    <div className="ringEnd">{level2}</div>
                    <div className="ringEnd">{level3}</div>
                </>
            )
        }

        if (ring > level1Limit && ring <= level2Limit) {
            return (
                <>
                    <div className="ringEnd">{level1}</div>
                    <div className="ringEnd">{level2}</div>
                    <div className="ringEnd">{level3}</div>
                    <div className="ringEnd">{level0}</div>
                </>
            )
        }

        if (ring > level2Limit && ring <= level3Limit) {
            return (
                <>
                    <div className="ringEnd">{level2}</div>
                    <div className="ringEnd">{level3}</div>
                    <div className="ringEnd">{level0}</div>
                    <div className="ringEnd">{level1}</div>
                </>
            )
        }
    }

    const getRandomValue = () => {
        return Math.floor(Math.random() * (100 - 1) + 1);
    }

    const generateRandomRings = () => {
        playRingEnd();
        setRing1(getRandomValue())

        setTimeout(
            () => {
                playRingEnd()
                setRing2(getRandomValue())
            }
            , 1000)
        setTimeout(
            () => {
                playRingEnd()
                setRing3(getRandomValue())
                setSpin(false);
            }
            , 2000)

    }

   
    return (
        <div className="">
            <span className="header">
                <img className="visual" src={visual} alt="Logo" />
                <div className="title">{title}</div>
            </span>
            <div className="pipe" ></div>

            <div className="slot">
                <div className="row">
                    {renderRow(ring1)}
                </div>
                <div className="row">
                    {renderRow(ring2)}
                </div>
                <div className="row">
                    {renderRow(ring3)}
                </div>

            </div>
            <div className="slot">
                <div className="rowTitle">
                    {firstValve}
                </div>
                <div className="rowTitle">
                    {secondValve}
                </div>
                <div className="rowTitle">
                    {thirdValve}
                </div>

            </div>
            <div className="footer">
                    <div className="result">
                        <p className="resultTitle">{benchmarkResultTitle}</p>
                        <p className="resultText">{benchmarkResult}</p>
                    </div>
        
                <button className="spinButton" disabled={spin} onClick={() => startPlay()}>{runTest}</button>
                
                <div className="cta">
                    
                    Genug von Glücksspiel? < br />
                    Nutze den <a href="https://devboost.com/potentialanalyse">DevBoost Development Benchmark</a> für eine zuverlässige Bewertung und nützlich Optimierungstipps. <br/>
                    <div className='disclaimer'>Glücksspielteilnahme ab 18 Jahren | DevBoost kann süchtig machen | Gewinnchance 1 : 1.000</div>
                </div>
             
            </div>
             
        </div>

    )
}

export default App;
